import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "store";
import "styles/index.css";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LoadingCom from "components/Loading/LoadingCom";
import ErrorBoundary from "components/error/ErrorBoundary";


const container = document.getElementById("app");
const root = createRoot(container);

const queryClient = new QueryClient();

root.render(
  <Suspense fallback={<LoadingCom/>}>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <MaterialUIControllerProvider>
        <Provider store={store}>
          <ErrorBoundary>
          <App />
          </ErrorBoundary>
        </Provider>
      </MaterialUIControllerProvider>
    </QueryClientProvider>
  </BrowserRouter>
  </Suspense>
);
